<template>
  <div style="background: #F6F6F6; height: 100%; padding: 0 5px;">
    <van-nav-bar title="订单详情" left-arrow placeholder @click-left="goBack" />

    <div class="container status">
      <div class="status-title">
        <img style="width: 0.8649rem; height: 0.8649rem;" src="./car.png" alt="">
        <span>
          {{
            goodsDetails.status == '1' ? '待发货' :
            goodsDetails.status == '0' ? '待支付' :
              goodsDetails.status == '3' ? '交易成功' :
                goodsDetails.status == '2' ? '待收货' :
                  goodsDetails.status == '5' ? '已取消' : '暂无状态'
          }}
        </span>
      </div>
      <p v-if="goodsDetails.status == '5'">{{ goodsDetails.calmsg }}</p>
    </div>

    <div class="container shangpinInfo">
      <div class="editBtn">
        <span class="shangpinInfo-title">
          配送信息
        </span>
        <div>
          <button v-if="goodsDetails.status == '1' && goodsDetails.modificationAddress == '1'" @click="editAddress"><span
              style="padding: 0.13rem 0.62rem; color: #333333;">修改</span></button>
        </div>
      </div>
      <div class="shangpinInfo-address">
        <span>{{ orderLogistics.address }}</span>
      </div>
      <div class="shangpinInfo-name">
        {{ orderLogistics.userName }} {{ orderLogistics.telNum }}
      </div>
    </div>

    <div class="container shangpin">
      <div class="shangpin-shop">
        <span class="shangpin-shop-img">
          <van-image round width="0.8108rem" height="0.8108rem" fit="cover" :src="goodsDetails.shopUrl" />
        </span>
        <span class="shangpin-shop-shopName" @click="enterStore">{{ goodsDetails.shopName }}</span>
        <span @click="enterStore">
          <van-icon name="arrow" size="13" />
        </span>
      </div>

      <div class="shangpin-info">
        <!-- <img style="height: 2.1622rem;" :src="goodsDetails.picUrl" alt=""> -->
        <span class="shangpin-info-img">
          <van-image width="2.1622rem" height="2.1622rem" fit="cover" :src="goodsDetails.picUrl" />
        </span>
        <div class="shangpin-info-right">
          <p>{{ goodsDetails.name }}</p>
          <p>
            <!-- <img class="shangpin-info-right-img" src="./mi.png" alt=""> -->
            <!-- 改这里（陈曹宇） -->
            <span style="font-size: 0.4865rem;">{{ goodsDetails.osalesPrice }} <span
                style="font-size: 12px;">元</span></span>
          </p>
          <p>
            <span>规格：{{ goodsDetails.specInfo ? goodsDetails.specInfo : '统一规格' }}</span>
            <span>x{{ goodsDetails.quantity }}</span>
          </p>
        </div>
      </div>
      <ul>
        <!-- 改这里（陈曹宇） -->
        <li style="margin-bottom: 0.2rem;color:#999">实际支付</li>
        <li>
          <span>现金（元）</span>
          <span style="color: #E54320;">{{ goodsDetails.money }} <span style="color: #E54320;">元</span> </span>
        </li>
        <li>
          <span>驿马</span>
          <span style="color: #E54320;">{{ goodsDetails.salesPrice }} <span style="color: #E54320;">驿马</span></span>
        </li>
        <li v-for="(item, index) in goodsDetails.couponDTO" :key="index">
          <span>{{ item.type }} <span style="color: #999999;">{{ item.name }}</span></span>
          <span style="color: #E54320;">-￥ {{ item.money }} <span style="color: #E54320;"></span></span>
        </li>
        <li>
          <span>运费（驿马）</span>
          <span style="color: #E54320;">{{ goodsDetails.freightPrice }} <span style="color: #E54320;">驿马</span></span>
        </li>
        <!-- <li>
                              <span>商品总价</span>
                              <span>{{ goodsDetails.totalPrices }}</span>
                            </li>
                            <li style="margin-bottom: 0.1351rem">
                              <span>邮费</span>
                              <span>{{ goodsDetails.freightPrice }}</span>
                            </li>
                            <li>
                              <span>实付驿马</span>
                              <span style="color: #E54320;">{{ goodsDetails.paymentPrice }}</span>
                            </li> -->
      </ul>
    </div>

    <div class="container time">
      <ul>
        <li>
          <span>订单编号</span>
          <span>
            <span style="color: #999;">{{ goodsDetails.orderId }}</span>
            <span style="margin: 0 0.1351rem;">|</span>
            <span class="span" @click="clipBoard(goodsDetails.orderId)">复制</span>
          </span>
        </li>
        <li>
          <span>创建时间</span>
          <span>{{ goodsDetails.createTime }}</span>
        </li>
        <li v-show="goodsDetails.status != '0'">
          <span>兑换时间</span>
          <span>{{ goodsDetails.paymentTime }}</span>
        </li>
        <li v-show="goodsDetails.status == '2' || goodsDetails.status == '3'">
          <span>发货时间</span>
          <span>{{ goodsDetails.deliveryTime }}</span>
        </li>
        <li v-show="goodsDetails.status == '3'">
          <span>完成时间</span>
          <span>{{ goodsDetails.receiverTime }}</span>
        </li>
      </ul>
    </div>

    <div style="padding-bottom: 1.8919rem;"></div>

    <div class="foots" v-show="goodsDetails.status == '2' || goodsDetails.status == '3'">
      <span></span>
      <span>
        <van-button round type="default" size="small" @click="show = true">查看物流</van-button>
      </span>
    </div>

    <div class="foots" v-show="goodsDetails.status == '0'">
      <span style="margin-right: 0.5405rem;">
        <van-button round plain type="default" color="#E54320" size="small" @click="abandonOrder">取消订单</van-button>
      </span>
      <span style="margin-right: 0.5405rem;">
        <van-button round type="default" color="#E54320" size="small" @click="confirmPay">立即付款</van-button>
      </span>
    </div>

    <van-dialog v-model="isShowWin" title="确认取消" show-cancel-button confirmButtonColor="#E54320" theme="round-button"
      @confirm="confirmAgain" @cancel="() => { isShowWin = false }">
    </van-dialog>
    <ZhiFu :show="showPay" :price="goodsDetails.money" :data="goodsDetails.orderId" @getSend="getShow"></ZhiFu>
    <PaySuccess :paySuccessBoolean="isPaySuccess" :data="goodsDetails.orderId" @closeWin="closeWin" :page="1" />
    <div>
      <van-action-sheet v-model="show" title="物流详情">
        <div class="content">
          <div class="content-header">
            <div class="content-header-left">
              <img style="height: 0.7027rem;" src="./wuliu.png" alt="">
              <span>{{ orderLogistics.logisticsDesc }}：{{ orderLogistics.logisticsNo }}</span>
            </div>
            <div class="content-header-right">
              <span class="span" @click="clipBoard(orderLogistics.logisticsNo)">复制</span>
            </div>
          </div>

          <div class="content-step">
            <van-steps direction="vertical" :active="steps.length - 1" active-color="#E54320">
              <van-step v-for="(x, i) in steps" :key="i">
                <h3>{{ x.context }}</h3>
                <p>{{ x.tiem }}</p>
              </van-step>
            </van-steps>

            <div v-show="!steps.length" class="content-info">
              <img class="content-info-img" src="./step.png" alt="">
              <p>暂无物流信息</p>
            </div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <div class="editAddress" v-if="goodsDetails.status == '1' && goodsDetails.modificationAddress == '1'">
      <van-tabbar :fixed="isTop">
        <button @click="editAddress"><span>修改地址</span></button>
      </van-tabbar>
    </div>
  </div>
</template>

<script>



import { orderDetail, cancelOrder } from "@/api/detail"
import ClipBoard from "clipboard"
import ZhiFu from '@/views/milimallDetail/ZhiFu.vue'
import PaySuccess from '@/views/milimallDetail/PaySuccess.vue'
import { Toast } from "vant"
export default {
  components: { ZhiFu, PaySuccess },
  data() {
    return {
      showPay: false,//支付弹出层默认不显示（陈曹宇）
      isPaySuccess: false,//订单是否支付成功
      isShowWin: false,
      goodsDetails: {},
      show: false,
      expressInfoEntity: '', // 有无物流
      steps: [], // 物流步骤
      orderLogistics: {},
      label: ''
    }
  },

  created() {
    // const session = '$2a$10$XoICtv/xtZcU9P4sZlHZdu7EFaKiWnNfH.WrLt/mTrHELR1zB8XGG'
    const session = this.$route.query.session
    if (session) {
      this.$store.commit('setSession', session)
    }
    this.getPage()
  },

  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    const logistic = this.$route.query.logistic
    if (logistic == 'true') {
      this.show = true
    }
    window.paySuccess = this.paySuccess
  },
  activated() {
    this.getPage()
  },
  methods: {
    //修改地址
    editAddress() {
      this.$router.push({
        path: "/editAddress",
        query: {
          // id: '1635120877450399746',
          id: this.$route.query.id,
          shopType: '1'//侠客集市
        },
      });
    },
    closeWin(value) {
      this.isPaySuccess = value
      this.getPage()
      this.$router.go(0)
    },
    getShow(val) {
      this.showPay = val
    },
    paySuccess() {
      this.isPaySuccess = true
    },
    confirmPay() {
      this.showPay = true
    },
    //取消订单
    abandonOrder() {
      this.isShowWin = true
    },
    //确认取消订单
    confirmAgain() {
      cancelOrder({ orderId: this.goodsDetails.orderId }).then(() => {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.miliMallOrderCancel.postMessage("up")
        }
        if (isAndroid) {
          // Toast('去订单列表')
          window.android.miliMallOrderCancel()
        }
      })
    },
    getPage() {
      orderDetail(this.$route.query.id).then(res => {
        // orderDetail('1635120877450399746').then(res => {
        if (res.data.code === 0) {
          this.goodsDetails = res.data.data.goodsDetails
          if (!res.data.data.goodsDetails.couponDTO) {
            this.goodsDetails.couponDTO = []
          }
          // Toast(this.goodsDetails.status)
          this.orderLogistics = res.data.data.orderLogistics
          const Boonlern = res.data.data.expressDetails['data']
          if (Boonlern) {
            this.steps = res.data.data.expressDetails.data
          } else {
            this.steps = []
          }
        }
      })
    },

    clipBoard(orderNo) {
      const clipBoard = new ClipBoard(".span", {
        text: () => {
          return orderNo
        }
      })
      clipBoard.on("success", () => {
        Toast.success("复制成功")
        clipBoard.destroy()
      })
      clipBoard.on("error", () => {
        Toast.fail("复制失败")
      })
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        //  这里的vm指的就是vue实例，可以用来当做this使用
        vm.fromPath = from.path  //获取上一级路由的路径
        vm.beforeRoute = from.path
        // console.log(vm.fromPath, 'fromPath')

      })
    },
    goBack() {
      if (this.beforeRoute == '/miliOrder') {
        window.android.returnHomePage()
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up")
        }
        if (isAndroid) {
          window.android.close()
        }
      }
    },
    /*   goList() {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        Toast('走不走？')
        if (isIOS) {
          window.webkit.messageHandlers.miliMallOrderCancel.postMessage("up")
        }
        if (isAndroid) {
          window.android.miliMallOrderCancel()
        }
      }, */
    enterStore() {
      this.$router.push({
        path: '/miliShop',
        query: {
          id: this.goodsDetails.shopId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  background: white;
  padding: 0.4054rem 0.3514rem;
  box-sizing: border-box;
  margin-top: 0.2703rem;
  border-radius: 5px;
}

.editAddress {
  .van-tabbar {

    display: flex;
    justify-content: flex-end;
    align-items: center;
    // height: 1.6rem;

    button {
      width: 2.5946rem;
      height: 0.8649rem;
      margin-right: 0.6919rem;
      border: 1px solid #999999;
      background: #fff;
      border-radius: 0.4324rem;
    }
  }
}

.status {
  &-title {
    display: flex;
    align-items: center;

    span {
      font-size: 0.5405rem;
      color: #333;
      margin-left: 0.2703rem;
    }

    p {
      font-size: 0.3784rem;
      color: #333;
    }
  }
}

.shangpinInfo {
  padding: 0.3514rem;

  .editBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border: 1px solid #999999;
      background: #fff;
      border-radius: 0.4324rem;
    }
  }

  &-title {
    font-size: 0.3784rem;
    color: #333;
  }

  &-address {
    margin: 0.2162rem 0 0.1351rem;
    font-size: 0.3243rem;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-name {
    font-size: 0.2703rem;
    color: #333;
  }
}

.shangpin {
  &-shop {
    display: flex;
    align-items: center;
    margin-bottom: 0.2703rem;

    &-img {
      .van-image {
        /deep/ .van-image__img {
          height: 0.9459rem !important;
        }
      }
    }

    &-shopName {
      font-size: 14px;
      color: #333;
      margin: 0 10px;
    }
  }

  &-info {
    font-size: 0.2703rem;
    display: flex;
    margin-bottom: 0.2703rem;

    &-img {
      .van-image {
        /deep/ .van-image__img {
          height: 2.1622rem !important;
        }
      }
    }

    &-right {
      margin-left: 0.2703rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-img {
        width: 17px;
        height: 17px;
        transform: translateY(2px);
      }
    }

    &-right p:nth-of-type(1) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    &-right p:nth-of-type(2) {
      color: #E54320;
    }

    &-right p:nth-of-type(3) {
      color: #999;
      display: flex;
      justify-content: space-between;
    }
  }

  ul {
    font-size: 14.0008px;

    li {
      display: flex;
      justify-content: space-between;
    }

    li span:first-child {
      color: #333;
    }

    li span:last-child {
      color: #999;
    }
  }
}

.time {
  font-size: 0.3784rem;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.0541rem;
  }

  li span:first-child {
    color: #333;
  }

  li span:last-child {
    color: #999;
  }

  ul li:last-child {
    margin-bottom: 0;
  }
}

.foots {
  width: 100%;
  padding: 0.3784rem 0.4324rem;
  box-sizing: border-box;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;

  .van-button--mini {
    width: 2.2703rem;
    height: 0.7568rem;
  }
}

.content {
  padding: 0 0.4054rem;
  min-height: 10.8108rem;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #4A4A4A;

    &-left {
      display: flex;
      align-items: center;

      img {
        margin-right: 0.1351rem;
      }
    }

    &-right {
      display: flex;
      align-items: center;

      a {
        color: #4A4A4A;
      }
    }
  }

  &-info {
    text-align: center;
    font-size: 0.5405rem;
    margin-top: 1.6216rem;
    color: #4A4A4A;

    &-img {
      width: 50%;
    }
  }
}
</style>